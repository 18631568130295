import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "next-themes";
import { EventType, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";

import App from "./App.tsx";
import "./index.css";
import { loginRequest } from "./authConfig.tsx";
import { DarkTheme } from "./context/ThemeProvider.tsx";
import { acquireAccessToken } from "./authConfig.tsx";
import { msalInstance } from "./authConfig.tsx";

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    acquireAccessToken(msalInstance);
  }
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <DarkTheme>
      <ThemeProvider attribute="class">
        <MsalProvider instance={msalInstance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            // errorComponent={<div>Something went wrong</div>}
            // loadingComponent={<div>Loading...</div>}
          >
            <App />
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </ThemeProvider>
    </DarkTheme>
  </BrowserRouter>
);
