import donut from "/donut.png";

import whitedot from "/whitedot.svg";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

interface MiniChartProps {
  indexScore: number;
  width: number;
  smallGauge: boolean;
  idle: boolean;
}
const MiniChart = ({ indexScore, width, smallGauge, idle }: MiniChartProps) => {
  const indicatorPosition = indexScore / 100;
  const rotationValue = `rotate(calc(${indicatorPosition} * 140deg))`;
  const translationValue = `translateY(40%)`;
  const percentageValue = width + "px";
  return (
    <>
      {!idle ? (
        <div
          id="chart"
          className="flex justify-center items-center float-left   relative"
        >
          <img
            style={{
              transform: "",
              width: `${width}px`,
            }}
            src={donut}
            className={`${width}px`}
            // className="w-full"
          />

          {smallGauge ? (
            // <div className="absolute  right-[32px] top-[5px] text-xs dark:text-black">
            //   H
            // </div>
            <></>
          ) : (
            <div className="absolute font-bold right-[34px] top-[8px] text-xs ">
              High
            </div>
          )}
          <div
            className=""
            style={{
              position: "absolute",
              zIndex: 30,
              margin: "auto",
              width: `${percentageValue}`,
              aspectRatio: "1/1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   transform: `${rotationValue} ${translationValue}`,
              transform: "rotate(calc(-75 * 1deg)) translateY(41%)",
            }}
          >
            <img src={whitedot} style={{ width: "15%", margin: "auto" }} />
          </div>
          {smallGauge ? (
            <></>
          ) : (
            // <div className="absolute left-[88px] text-xs bottom-[50px] dark:text-black ">
            //   L
            // </div>
            <div className="absolute left-[120px] font-bold text-xs bottom-[75px]">
              Low
            </div>
          )}

          <div className="absolute z-60 flex flex-col justify-center items-center h-full mx-auto">
            <p
              id="ind_t_2"
              className={`text-2xl  font-semibold ${
                smallGauge
                  ? "text-black dark:text-[#FFFFF7]"
                  : "text-black dark:text-white "
              } `}
            >
              {indexScore ? indexScore : "--"}
            </p>
          </div>
        </div>
      ) : (
        <div
          id="chart"
          className="flex justify-center items-center float-left   relative"
        >
          <img
            style={{
              transform: "",
              width: `${width}px`,
            }}
            src={donut}
            className={`${width}px`}
            // className="w-full"
          />

          {smallGauge ? (
            // <div className="absolute  right-[32px] top-[5px] text-xs dark:text-black">
            //   H
            // </div>
            <></>
          ) : (
            <div className="absolute font-bold right-[34px] top-[8px] text-xs ">
              High
            </div>
          )}
          <div
            className=""
            style={{
              position: "absolute",
              zIndex: 30,
              margin: "auto",
              width: `${percentageValue}`,
              aspectRatio: "1/1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transform: `${rotationValue} ${translationValue}`,
            }}
          >
            <img src={whitedot} style={{ width: "15%", margin: "auto" }} />
          </div>
          {smallGauge ? (
            <></>
          ) : (
            <div className="absolute left-[120px] font-bold text-xs bottom-[75px]">
              Low
            </div>
          )}

          <div className="absolute z-60 flex flex-col justify-center items-center h-full mx-auto">
            <p
              id="ind_t_2"
              className={`text-2xl  font-semibold ${
                smallGauge
                  ? "text-black dark:text-[#FFFFF7]"
                  : "text-black dark:text-white "
              } `}
            >
              {indexScore ? indexScore : "--"}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MiniChart;
