export default function ProfitabilityHelp() {
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <p className="leading-relaxed text-base pb-3">
          The profitability index seeks to understand the overall management of
          the farm. While genetics, feed, infrastructure, and climate play a key
          role in the overall risk, management practices are determinant factors
          of success. The score is an ADAPTA-designed point system based on the
          answers per livestock. Make sure the answers provided by the farmers
          are as accurate as possible.
        </p>
      </div>
      {/* End Card */}
    </>
  );
}
