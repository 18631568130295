//@ts-ignore
import MainDashboard from "../../components/dashboards/main_dashboard";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { axiosPrivateOid } from "../../api/axiosPrivate";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Info } from "lucide-react";
import background from "/img/chicken.jpg";
const schema = yup.object().shape({
  requester_name: yup.string().required("Name is required"),
  help_type: yup.object({
    value: yup.string().required("Please select your query type"),
  }),

  requester_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  // requester_phone_number: yup
  //   .string()
  //   .required("required")
  //   .min(10, "too short")
  //   .max(14, "too long"),
  requester_company: yup.string(),
  // requester_company_website: yup.string().url("Invalid URL"),
  message: yup.string().required("Message is required"),
});
export default function HelpPage() {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const options = [
    { value: "Agriculture Questions", label: "Agriculture Questions" },
    { value: "Technical Questions", label: "Technical Questions" },
    { value: "General Questions", label: "General Questions" },
    { value: "Feedback", label: "Feedback" },
  ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    console.log("data", data);

    data.help_type = data.help_type.value;

    console.log("final daa", data);
    axiosPrivateOid<any>({
      method: "POST",
      url: "/emails/dashboard/help",
      data: data,
    })
      .then(() => {
        // const responseData = res.data;
        toast.success("Message sent successfully");
        reset();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: "black", // Set placeholder text color
    }),
  };
  return (
    <div
      className="bg-cover bg-center min-h-screen "
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <MainDashboard>
        <div>
          <>
            {/* Contact Us */}
            <div className="max-w-[85rem] px-0 pt-5 md:px-6 lg:px-8 lg:pt-5 mx-auto">
              <div className="mx-auto">
                <div className="text-center">
                  <h1 className="text-2xl font-bold text-slate-100 sm:text-2xl c ">
                    Get in touch
                  </h1>
                  <p className="mt-1 text-slate-100 ">
                    Our team would love to hear from you.
                  </p>
                </div>
                {/* <div className="text-center justify-center flex mt-3">
                <img src="/img/logo.png" alt="logo" />
              </div> */}
                <div className="mt-7  items-center justify-center   gap-6 lg:gap-16 w-[100%] md:w-[60%] mx-auto">
                  {/* Card */}
                  <div className="flex flex-col border  dark:bg-slate-900 dark:bg-transparent backdrop-blur-sm rounded-xl p-4 sm:p-6 lg:p-8 dark:border-gray-700">
                    <h2 className="mb-8 text-xl font-semibold text-slate-100 dark:text-gray-200">
                      Fill in the form
                    </h2>
                    {/* <div className="text-center justify-center flex mb-6">
                      <img src="/img/logo.png" alt="logo" />
                    </div> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="grid gap-4 dark:text-white">
                        {/* Grid */}
                        <div>
                          <div>
                            {/* <label htmlFor="name" className="sr-only">
                            Name
                          </label>
                          <input
                            type="text"
                            className="border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Name"
                            id="name"
                            {...register("requester_name")}
                          />{" "}
                          <p className="text-red-500 text-sm">
                            {errors.requester_name?.message}
                          </p> */}

                            {/* <select
                            className="h-10 py-2 px-4 pr-9 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            name="query-type"
                            id="query-type"
                            placeholder="Please select query type"
                          >
                            <option value="">Please select query type</option>
                            <option value="">Agriculture Questions</option>
                            <option value=""> Technical Questions</option>
                            <option value=""> General Questions</option>
                            <option value="">Feedback</option>
                          </select> */}
                            <Controller
                              name={"help_type"} // for the gender field
                              control={control} // obtained from the useForm hook
                              render={({ field }) => {
                                return (
                                  <Select
                                    styles={customStyles}
                                    className="dark:text-black placeholder-red-400"
                                    {...field}
                                    options={options}
                                    placeholder="Select your query type"
                                  />
                                );
                              }}
                            />

                            <p className="text-red-500">
                              {
                                //@ts-ignore
                                errors.help_type?.value?.message
                              }
                            </p>
                          </div>
                        </div>
                        {/* End Grid */}
                        <div>
                          <label htmlFor="name" className="sr-only">
                            Name
                          </label>
                          <input
                            type="text"
                            className="placeholder-black dark:placeholder:text-white border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-white"
                            placeholder="Name"
                            id="name"
                            {...register("requester_name")}
                          />{" "}
                          <p className="text-red-500 text-sm">
                            {errors.requester_name?.message.toString()}
                          </p>
                        </div>
                        <div>
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <input
                            type="email"
                            autoComplete="email"
                            className="placeholder-black dark:placeholder:text-white border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Email"
                            id="email"
                            {...register("requester_email")}
                          />{" "}
                          <p className="text-red-500 text-sm">
                            {errors.requester_email?.message.toString()}
                          </p>
                        </div>
                        {/* <div className={styles["my-phone-input"]}>
                        <label htmlFor="phone" className="sr-only">
                          Phone Number
                        </label>
                        <Controller
                          control={control}
                          name="requester_phone_number"
                          render={({ field: { onChange } }) => (
                            <PhoneInput
                              className="w-full"
                              defaultCountry="ke"
                              inputClassName="w-full"
                              name="phone"
                              onChange={onChange}
                            />
                          )}
                        />
                        <p className="text-red-500 text-sm">
                          {errors.requester_phone_number?.message}
                        </p>
                      </div> */}
                        {/* Grid */}
                        {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="companyName" className="sr-only">
                            Company Name
                          </label>
                          <input
                            type="text"
                            {...register("requester_company")}
                            className="border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Company Name"
                            id="companyName"
                          />
                          <p className="text-red-500 text-sm">
                            {errors.requester_company?.message}
                          </p>
                        </div>
                        <div>
                          <label htmlFor="companyWebsite" className="sr-only">
                            Company Website
                          </label>
                          <input
                            type="url"
                            {...register("requester_company_website")}
                            className="border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Company Website"
                            id="website"
                          />
                          <p className="text-red-500 text-sm">
                            {errors.requester_company_website?.message}
                          </p>
                        </div>
                      </div> */}
                        {/* End Grid */}
                        <div>
                          <label htmlFor="message" className="sr-only">
                            Message
                          </label>
                          <textarea
                            {...register("message")}
                            rows={4}
                            className="placeholder-black dark:placeholder:text-white border py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Message / Queries"
                            id="message"
                          />

                          <p className="text-red-500 text-sm">
                            {errors.message?.message.toString()}
                          </p>
                        </div>
                      </div>
                      {/* End Grid */}

                      <div className="mt-4 grid">
                        <button
                          type="submit"
                          className="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800"
                        >
                          Send Message
                        </button>
                      </div>
                    </form>

                    <div className="flex ps-2 items-center mt-5">
                      <Info size={30} color="white" />
                      <p className=" ps-2 text-xs text-white">
                        Working hours are from 9 AM-6 PM. Messages outside these
                        times will be responded as soon as possible.Messages can
                        be sent to junk email.
                      </p>
                    </div>
                  </div>
                  {/* End Card */}
                </div>
              </div>
            </div>

            {/* End Contact Us */}
          </>
        </div>
      </MainDashboard>
      {/* <div className="flex ps-2 items-center">
        <Info color="white" />
        <p className=" ps-2 text-xs text-white">
          Working hours are from 9 AM-6 PM. Messages outside these times will be
          responded as soon as possible.
        </p>
      </div> */}
    </div>
  );
}
