import { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import "@reach/combobox/styles.css";

export default function Places({
  geoJson,
  setSelectedLocation,
  selectedLocation,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA",
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      geoJson={geoJson}
      setSelectedLocation={setSelectedLocation}
      selectedLocation={selectedLocation}
    />
  );
}

export function Map({ geoJson, setSelectedLocation, selectedLocation }) {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(18);
  // const [isPlacingMarker, setIsPlacingMarker] = useState(false);

  const isPlacingMarker = useRef(false);
  useEffect(() => {
    if (map && geoJson?.geometry?.coordinates?.length > 0) {
      // Clear existing GeoJSON data if any
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      // Add the new GeoJSON data
      map.data.addGeoJson(geoJson);
      map.data.setStyle({
        fillColor: "red",
        strokeColor: "red",
        strokeWeight: 2,
        fillOpacity: 0.4,
      });
    } else if (map) {
      // Clear the map data if geoJson is null or empty
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    }
  }, [map, geoJson]);
  // Function to load the map
  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);

    // Add custom control
    const controlDiv = document.createElement("div");
    controlDiv.style.padding = "10px";
    //@ts-ignore
    controlDiv.index = 1;

    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to place marker";
    controlDiv.appendChild(controlUI);

    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "📌";
    controlUI.appendChild(controlText);

    controlUI.addEventListener("click", () => {
      // setIsPlacingMarker(true);
      //@ts-ignore
      isplacingMarker.current = true;
    });

    mapInstance.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      controlDiv
    );
  }, []);
  const mapOptions = {
    scrollwheel: true, // This enables zoom on scroll without Ctrl
    gestureHandling: "greedy",
    mapTypeId: "hybrid",
    // You can also disable specific controls individually:
    // zoomControl: false,
    // mapTypeControl: false,
    // scaleControl: false,
    // streetViewControl: false,
    // rotateControl: false,
    // fullscreenControl: false
  };
  const handleMapClick = useCallback(
    (event) => {
      console.log("map clicked", event);
      if ((isPlacingMarker.current = true)) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLocation({ lat, lng });
        setZoom(20);
        isPlacingMarker.current = false;
      }
    },
    [setSelectedLocation]
  );

  return (
    <>
      <GoogleMap
        options={mapOptions}
        zoom={zoom}
        onLoad={onMapLoad}
        center={{
          lat: selectedLocation?.lat || -8.410614002652656,
          lng: selectedLocation?.lng || 115.18877457548977,
        }}
        mapContainerClassName="map-container"
        onClick={handleMapClick}
      >
        {selectedLocation && (
          <Marker
            onDragEnd={(marker) => {
              setSelectedLocation({
                ...selectedLocation,
                lat: marker.latLng.lat(),
                lng: marker.latLng.lng(),
              });
              console.log(
                marker.latLng.lat(),
                marker.latLng.lng(),
                "lat and lng from marker"
              );
            }}
            draggable
            position={{
              lat: selectedLocation?.lat,
              lng: selectedLocation?.lng,
            }}
          />
        )}
      </GoogleMap>
    </>
  );
}
export function MapForReport({ lat, lng }) {
  // Function to load the map
  console.log(lat, lng, "dfdfdf");
  //   // Load the GeoJSON data into the map
  //   map.data.addGeoJson({
  //     type: "FeatureCollection",
  //     features: [
  //       {
  //         type: "Feature",
  //         properties: {},
  //         geometry: {
  //           coordinates: [
  //             [-73.95831944819373, 40.80011560101866],
  //             [-73.98200144349565, 40.7680563496657],
  //             [-73.97340005048385, 40.76460749246576],
  //             [-73.94922217203022, 40.797048286892306],
  //             [-73.95818135154668, 40.80048141633816],
  //           ],
  //           type: "LineString",
  //         },
  //       },
  //     ],
  //   });

  //   // Optionally, set a style for the GeoJSON features
  //   map.data.setStyle({
  //     icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
  //   });
  // };
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: "satellite",
    // You can also disable specific controls individually:
    // zoomControl: false,
    // mapTypeControl: false,
    // scaleControl: false,
    // streetViewControl: false,
    // rotateControl: false,
    // fullscreenControl: false
  };

  return (
    <>
      <GoogleMap
        zoom={18}
        options={mapOptions}
        // streetView={false}
        // {geoJson?.geometry?.coordinates?.length > 0 && (
        // )}
        center={{
          lat: lat || -0.023559,
          lng: lng || 37.906193,
        }}
        mapContainerClassName="map-container"
      >
        <Marker
          position={{
            lat: lat,
            lng: lng,
          }}
        />
      </GoogleMap>
    </>
  );
}

// export const PlacesAutocomplete = ({ setSelected, setCenter, setAddress }) => {
//   const {
//     ready,
//     value,
//     setValue,
//     suggestions: { status, data },
//     clearSuggestions,
//   } = usePlacesAutocomplete();

//   const latLngRegex = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;

//   const handleSelect = async (input) => {
//     setValue(input, false);
//     clearSuggestions();

//     if (latLngRegex.test(input)) {
//       // Input is a coordinate pair
//       const [lat, lng] = input.split(",").map(Number);
//       setSelected({ lat, lng });
//       setCenter({ lat, lng });
//     } else {
//       // Input is an address
//       setAddress(input);

//       const results = await getGeocode({ address: input });
//       const { lat, lng } = await getLatLng(results[0]);
//       setSelected({ lat, lng });
//       setCenter({ lat, lng });
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && latLngRegex.test(value)) {
//       handleSelect(value);
//     }
//   };

//   return (
//     <>
//       <Combobox onSelect={handleSelect}>
//         <ComboboxInput
//           value={value}
//           onChange={(e) => setValue(e.target.value)}
//           onKeyDown={handleKeyDown} // Detect Enter key press
//           disabled={!ready}
//           className="combobox-input"
//           placeholder="Search address or enter lat,lng"
//         />
//         <ComboboxPopover>
//           <ComboboxList>
//             {status === "OK" &&
//               data.map(({ place_id, description }) => (
//                 <ComboboxOption key={place_id} value={description} />
//               ))}
//           </ComboboxList>
//         </ComboboxPopover>
//       </Combobox>
//     </>
//   );
// };
