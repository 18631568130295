//@ts-ignore
// import ReactApexChart from "react-apexcharts";
// import GaugeComponent from "react-gauge-component";
import { useEffect, useState } from "react";
import MiniChart from "./MiniChart";
import GradientBarchartLivestock from "./GradientBarchartLivestock";

interface GaugeChartProps {
  pillar: string;
  firstScore: number | undefined;
  secondScore?: number | undefined;

  composite_climate_risk: number | undefined;
  categories: string[];
  tooltip?: string;
}

const TopGaugeCattle: React.FC<GaugeChartProps> = ({
  pillar,
  firstScore,
  //@ts-ignore
  idle,
  secondScore,
  composite_climate_risk,
  categories,
  tooltip,
}) => {
  console.log(firstScore, secondScore, "from cattle");
  const modifiedFirstScore = firstScore === 0 ? 13 : firstScore;
  const modifiedSecondScore = secondScore === 0 ? 13 : secondScore;

  let initialSeries;
  if (secondScore) {
    initialSeries = [
      {
        data: [modifiedFirstScore || 1000, modifiedSecondScore || 1000], // Replace with your data values
      },
    ];
  } else {
    initialSeries = [
      {
        data: [modifiedFirstScore || 1000], // Replace with your data values
      },
    ];
  }

  const [series, setSeries] = useState(initialSeries);
  useEffect(() => {
    setSeries(initialSeries);
    // window.dispatchEvent(new Event("resize"));
    console.log("resizee");
  }, [composite_climate_risk]);

  return (
    <>
      {/* Card */}
      <div className="group flex flex-col  p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <h3 className="font-semibold mb-4 text-sm leading-none tracking-tight ">
          {pillar} RISK
        </h3>
        {tooltip ? (
          <div className=" relative flex gap-[2%] lg:gap-[0px] justify-between flex-wrap">
            <div className="flex justify-center items-center w-[34%] lg:w-1/3">
              <MiniChart
                smallGauge
                idle={idle}
                // @ts-ignore
                indexScore={composite_climate_risk?.toFixed(0)}
                width={100}
              />
            </div>
            <div className="pr-3 pt-3 w-[64%] lg:w-[50%]">
              <div className="horizontal-bar-chart " id="chart">
                <GradientBarchartLivestock
                  //@ts-ignore
                  idle={idle}
                  categories={categories}
                  scores={series}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-center items-center">
              <MiniChart
                // @ts-ignore
                indexScore={composite_climate_risk?.toFixed(0)}
                width={120}
              />
            </div>
            <div className="pr-3 pt-3">
              <div className="horizontal-bar-chart" id="chart">
                <GradientBarchartLivestock
                  //@ts-ignore
                  idle={idle}
                  categories={categories}
                  scores={series}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default TopGaugeCattle;
