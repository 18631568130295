// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from "react";
import { RiskDataById } from "../../data/riskData";
import { ScoreData } from "../../data/scoreData";
import PdfPreviewComponent from "../PdfPreviewComponent";
import axiosPrivate, {
  axiosPrivateRiskScore,
  axiosProfitablity,
} from "../../api/axiosPrivate";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import toast from "react-hot-toast";
import { Heading1 } from "lucide-react";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { pdfOptions, replaceUnderscores } from "../../utils";
import PdfPrevLivestock from "../PdfPrevLivestock";
import generatePDF from "react-to-pdf";

export default function UserSearchesTable({ scoreList }: any) {
  const tableRef = useRef(null);
  const { userId } = useParams();
  console.log(userId);
  const [filter, setFilter] = useState("last_semester");
  const [open, setOpen] = useState(false);
  const [riskData, setRiskData] = useState<any>();
  const [isLivestock, setIsLivestock] = useState<any>("Crop");
  const [liveStockScores, setLiveStockScore] = useState<any>(true);
  const [liveStockPdfData, setLiveStockPdfData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const storedUserId = localStorage.getItem("tokenMsal");

  const getSearchData = async () => {
    const SEARCH_URL = `/v1/location_scores/${userId}/scores?page=1&items_per_page_=50&time_filter=${filter}`;
    await axiosPrivateRiskScore<any>({
      method: "GET",
      url: SEARCH_URL,
    })
      .then((data) => {
        setSearchData(data?.data?.scores);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getLivestockScores();
  }, []);

  useEffect(() => {
    getSearchData();
  }, [filter]);
  const toggleModal = () => {
    setOpen((s) => !s);
  };

  async function getLivestockScores() {
    const LIVESTOCK_URL = `/user-history/read/${storedUserId}`;
    axiosProfitablity<any>({ method: "GET", url: LIVESTOCK_URL })
      .then((data) => {
        setLiveStockScore(data?.data.scores);
        console.log("liveStock ", data.data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  const onPreviewScore = async (scoreId: string) => {
    Swal.showLoading();
    const RISK_URL = `/v1/location_scores/${scoreId}/score`;
    // setIsLoading(true);
    await axiosPrivateRiskScore<any>({
      method: "GET",
      url: RISK_URL,
    })
      .then((data) => {
        console.log("indivisual", data);
        if (
          data === null ||
          data === undefined ||
          Object.keys(data).length === 0
        ) {
          // Handle empty data
          Swal.fire({
            icon: "warning",
            title: "Area Data Not Available",
            text: "Error retriving data From Area",
          });
        } else {
          setRiskData(data.data);
          // setClimateScores(data.climate_scores);
          Swal.close();
          if (data.composite_total_risk > 69) {
            toast.error(
              "The risk in the selected area is too high.It is recommended not to proceed with this project."
            );
          }
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 100);

          toggleModal();
        }
        // setIsLoading(false);
        // setIsComputed(true);
        Swal.close();
      })
      .catch((error) => {
        // setIsLoading(false);
        console.error("API Error:", error);
        Swal.close;
      });
  };
  const onPreviewScoreLivestock = async (scoreId: string) => {
    Swal.showLoading();
    const RISK_URL = `/history/read/${scoreId}`;
    // setIsLoading(true);
    await axiosProfitablity<any>({
      method: "GET",
      url: RISK_URL,
    })
      .then((data) => {
        console.log("indivisual", data);
        if (
          data === null ||
          data === undefined ||
          Object.keys(data).length === 0
        ) {
          // Handle empty data
          Swal.fire({
            icon: "warning",
            title: "Area Data Not Available",
            text: "Error retriving data From Area",
          });
        } else {
          setLiveStockPdfData(data.data);
          // setClimateScores(data.climate_scores);
          Swal.close();
          if (data.composite_total_risk > 69) {
            toast.error(
              "The risk in the selected area is too high.It is recommended not to proceed with this project."
            );
          }
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 100);

          toggleModal();
        }
        // setIsLoading(false);
        // setIsComputed(true);
        Swal.close();
      })
      .catch((error) => {
        // setIsLoading(false);
        console.error("API Error:", error);
        Swal.close;
      });
  };

  const options = [
    { label: "Last Month", value: "this_month" },
    { label: "Last Quarter", value: "last_quarter" },
    { label: "Last Semester", value: "last_semester" },
    { label: "Last Year", value: "last_year" },
    { label: "Last 2 Years", value: "last_two_years" },
  ];
  const optionstype = [
    { label: "Livestock", value: "Livestock" },
    { label: "Crop", value: "Crop" },
  ];
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  function getStatusClassName(status: string) {
    let className = "";

    if (status === "Accepted") {
      className =
        "inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200";
    } else if (status === "Pending") {
      className =
        "inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200";
    } else if (status === "Declined") {
      className =
        "inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200";
    }

    return className;
  }
  const getColor = (percentage: number) => {
    if (percentage <= 30) {
      return "bg-green-600"; // Green
    } else if (percentage <= 60) {
      return "bg-yellow-400"; // Yellow
    } else {
      return "bg-red-600"; // Red
    }
  };

  const handlePrint = () => {
    const printContents = document.getElementById("printablediv")?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };
  const getTargetElement = () => document.getElementById("printablediv");
  const handleDownloadPDF = () => {
    // const modalContent = document.getElementById("printablediv");
    // console.log("generatePDF:modalContent: ", modalContent);
    // html2pdf()
    //   .set({
    //     pagebreak: { mode: "avoid-all" },
    //   })
    //   .from(modalContent)
    //   .save("modal_content.pdf");
    console.log("pdf run");
    generatePDF(getTargetElement, pdfOptions);
  };
  console.log("scorelisssss", scoreList);
  return (
    <>
      {!scoreList.length ? (
        <h1>No searches saved</h1>
      ) : (
        <>
          {isLivestock === "Livestock" ? (
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
                    {/* Header */}
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
                      <div>
                        {/* Input */}
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="hs-as-table-product-review-search"
                            className="sr-only"
                          >
                            Search
                          </label>
                          <div className="relative lg:w-64 xl:w-96">
                            <input
                              type="text"
                              id="hs-as-table-product-review-search"
                              name="hs-as-table-product-review-search"
                              className="py-2 px-3  pl-11  block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                              placeholder="Search"
                            />
                            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                              <svg
                                className="h-4 w-4 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        {/* End Input */}
                      </div>
                      <div>
                        <div className="inline-flex gap-x-2">
                          <Select
                            className="dark:text-black "
                            options={options}
                            onChange={(s) => setFilter(s.value)}
                            defaultValue={{
                              label: "Last Month",
                              value: "this_month",
                            }}
                          />
                          <Select
                            className="dark:text-black"
                            options={optionstype}
                            onChange={(s) => setIsLivestock(s.value)}
                            value={{
                              label: isLivestock,
                              value: isLivestock,
                            }}
                            defaultValue={{
                              label: "Crop",
                              value: "Crop",
                            }}
                          />
                          <button
                            onClick={onDownload}
                            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                          >
                            <svg
                              className="w-3 h-3"
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                              />
                            </svg>
                            Import excel
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Header */}
                    {/* Table */}
                    <table
                      ref={tableRef}
                      className="min-w-full  divide-y divide-gray-200 dark:divide-gray-700"
                    >
                      <thead className="bg-gray-50 dark:bg-slate-800">
                        <tr>
                          {/* <th scope="col" className="pl-6 py-3 text-left">
                        <label
                          htmlFor="hs-at-with-checkboxes-main"
                          className="flex"
                        >
                          <input
                            type="checkbox"
                            className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            id="hs-at-with-checkboxes-main"
                          />
                          <span className="sr-only">Checkbox</span>
                        </label>
                      </th> */}
                          <th
                            scope="col"
                            className="pl-6 lg:pl-3 pr-6 py-3 text-left"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Name
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-left">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Location (LatLng)
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-left">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Livestock
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-left">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Risk Score
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-left">
                            <div className="flex items-center gap-x-2">
                              <span className="`text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Created
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-left">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Location
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-left">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                Action
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {liveStockScores?.map((data) => (
                          <tr>
                            {/* <td className="h-px w-px whitespace-nowrap">
                          <div className="pl-6 py-3">
                            <label
                              htmlFor="hs-at-with-checkboxes-1"
                              className="flex"
                            >
                              <input
                                type="checkbox"
                                className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                id="hs-at-with-checkboxes-1"
                              />
                              <span className="sr-only">Checkbox</span>
                            </label>
                          </div>
                        </td> */}
                            <td
                              key={data?.score_id}
                              className="h-px w-px whitespace-nowrap"
                            >
                              <div className="pl-6 lg:pl-3 pr-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  {/* <span className="inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-blue-300 dark:bg-blue-700">
                                <span className="font-medium text-blue-800 leading-none dark:text-blue-200">
                                  {data.debtor_name.charAt(0)}
                                </span>
                              </span> */}
                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                                      {data.debtor_name}
                                    </span>
                                    {/* <span className="block text-sm text-gray-500">
                              account
                            </span> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="h-px w-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="block text-sm text-gray-500">
                                  {data.latitude}
                                </span>
                                <span className="block text-sm text-gray-500">
                                  {data.longitude}
                                </span>
                              </div>
                            </td>
                            <td className="h-px w-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="block text-sm text-gray-500">
                                  {replaceUnderscores(data?.livestock_type)}
                                </span>
                              </div>
                            </td>

                            <td className="h-px w-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <div className="flex items-center gap-x-3 w-40">
                                  <span className="text-xs text-gray-500">
                                    {data.composite_total_risk.toFixed(0)}
                                  </span>
                                  <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                                    <div
                                      className={getColor(
                                        data.composite_total_risk
                                      )}
                                      role="progressbar"
                                      style={{
                                        width: `${data.composite_total_risk}%`,
                                      }}
                                      aria-valuenow={data.composite_total_risk}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>

                            {/* <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className={getStatusClassName("Accepted")}>
                              Saved
                            </span>
                          </div>
                        </td> */}
                            <td className="h-px w-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {data?.search_date}
                                </span>
                              </div>
                            </td>
                            <td className="h-px w-px whitespace-nowrap">
                              <div className="px-6 py-1.5">
                                <button className="ml-3">
                                  <div className="py-3">
                                    <span className="text-sm text-gray-500">
                                      {data?.location_name}
                                    </span>
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td className="h-px w-px whitespace-nowrap">
                              <div className="px-6 py-1.5">
                                <button
                                  className="ml-3"
                                  onClick={() =>
                                    onPreviewScoreLivestock(data?.score_id)
                                  }
                                >
                                  <div className="py-1 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white">
                                    <i className="bi bi-eye"></i>
                                    View
                                  </div>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* End Table */}
                    {/* Footer */}
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
                      <div>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          <span className="font-semibold text-gray-800 dark:text-gray-200">
                            {scoreList?.total_count}
                          </span>{" "}
                          results
                        </p>
                      </div>
                      <div>
                        <div className="inline-flex gap-x-2">
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          >
                            <svg
                              className="w-3 h-3"
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                              />
                            </svg>
                            Prev
                          </button>
                          <button
                            type="button"
                            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          >
                            Next
                            <svg
                              className="w-3 h-3"
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Footer */}

                    <div
                      id="hs-bg-gray-on-hover-cards"
                      className={`hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto ${
                        open ? "open" : "hidden"
                      }`}
                    >
                      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-4xl lg:w-full m-3 lg:mx-auto">
                        <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700">
                          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                            <h3 className="font-bold text-gray-800 dark:text-gray-200">
                              Detailed Risk Report Analysis
                            </h3>
                            <div>
                              {/* Col */}
                              <div className="inline-flex gap-x-2 mr-5">
                                <button
                                  className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                  onClick={handleDownloadPDF}
                                >
                                  <i className="bi bi-download"></i>
                                  PDF
                                </button>
                                <button
                                  className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                                  onClick={handlePrint}
                                >
                                  <i className="bi bi-printer-fill"></i>
                                  Print
                                </button>
                              </div>
                              {/* Col */}
                              <button
                                type="button"
                                className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                                onClick={toggleModal}
                              >
                                <span className="sr-only">Close</span>
                                <svg
                                  className="w-3.5 h-3.5"
                                  width={8}
                                  height={8}
                                  viewBox="0 0 8 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="p-4 overflow-y-auto">
                            <PdfPrevLivestock
                              toggleModal={toggleModal}
                              profitData={liveStockPdfData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {" "}
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
                      {/* Header */}
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
                        <div>
                          {/* Input */}
                          <div className="sm:col-span-1">
                            <label
                              htmlFor="hs-as-table-product-review-search"
                              className="sr-only"
                            >
                              Search
                            </label>
                            <div className="relative lg:w-64 xl:w-96">
                              <input
                                type="text"
                                id="hs-as-table-product-review-search"
                                name="hs-as-table-product-review-search"
                                className="py-2 px-3  pl-11  block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Search"
                              />
                              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                                <svg
                                  className="h-4 w-4 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          {/* End Input */}
                        </div>
                        <div>
                          <div className="inline-flex gap-x-2">
                            <Select
                              className="dark:text-black"
                              options={options}
                              onChange={(s) => setFilter(s.value)}
                              defaultValue={{
                                label: "Last Month",
                                value: "this_month",
                              }}
                            />
                            <Select
                              className="dark:text-black"
                              options={optionstype}
                              onChange={(s) => setIsLivestock(s.value)}
                              value={{
                                label: isLivestock,
                                value: isLivestock,
                              }}
                              defaultValue={{
                                label: "Crop",
                                value: "Crop",
                              }}
                            />
                            <button
                              onClick={onDownload}
                              className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                            >
                              <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                />
                              </svg>
                              Import excel
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* End Header */}
                      {/* Table */}
                      <table
                        ref={tableRef}
                        className="min-w-full  divide-y divide-gray-200 dark:divide-gray-700"
                      >
                        <thead className="bg-gray-50 dark:bg-slate-800">
                          <tr>
                            {/* <th scope="col" className="pl-6 py-3 text-left">
                      <label
                        htmlFor="hs-at-with-checkboxes-main"
                        className="flex"
                      >
                        <input
                          type="checkbox"
                          className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-main"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </th> */}
                            <th
                              scope="col"
                              className="pl-6 lg:pl-3 pr-6 py-3 text-left"
                            >
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Name
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-left">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Location (LatLng)
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-left">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Crop
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-left">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Risk Score
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-left">
                              <div className="flex items-center gap-x-2">
                                <span className="`text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Created
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-left">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Location
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-left">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                                  Action
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                          {searchData?.map((data) => (
                            <tr>
                              {/* <td className="h-px w-px whitespace-nowrap">
                        <div className="pl-6 py-3">
                          <label
                            htmlFor="hs-at-with-checkboxes-1"
                            className="flex"
                          >
                            <input
                              type="checkbox"
                              className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              id="hs-at-with-checkboxes-1"
                            />
                            <span className="sr-only">Checkbox</span>
                          </label>
                        </div>
                      </td> */}
                              <td
                                key={data?.score_id}
                                className="h-px w-px whitespace-nowrap"
                              >
                                <div className="pl-6 lg:pl-3 pr-6 py-3">
                                  <div className="flex items-center gap-x-3">
                                    {/* <span className="inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-blue-300 dark:bg-blue-700">
                              <span className="font-medium text-blue-800 leading-none dark:text-blue-200">
                                {data.debtor_name.charAt(0)}
                              </span>
                            </span> */}
                                    <div className="grow">
                                      <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                                        {data.debtor_name}
                                      </span>
                                      {/* <span className="block text-sm text-gray-500">
                            account
                          </span> */}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="h-px w-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="block text-sm text-gray-500">
                                    {data.latitude}
                                  </span>
                                  <span className="block text-sm text-gray-500">
                                    {data.longitude}
                                  </span>
                                </div>
                              </td>
                              <td className="h-px w-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="block text-sm text-gray-500">
                                    {data.crop}
                                  </span>
                                </div>
                              </td>

                              <td className="h-px w-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <div className="flex items-center gap-x-3 w-40">
                                    <span className="text-xs text-gray-500">
                                      {data.composite_total_risk.toFixed(0)}
                                    </span>
                                    <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                                      <div
                                        className={getColor(
                                          data.composite_total_risk
                                        )}
                                        role="progressbar"
                                        style={{
                                          width: `${data.composite_total_risk}%`,
                                        }}
                                        aria-valuenow={
                                          data.composite_total_risk
                                        }
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>

                              {/* <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className={getStatusClassName("Accepted")}>
                            Saved
                          </span>
                        </div>
                      </td> */}
                              <td className="h-px w-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {data?.search_date}
                                  </span>
                                </div>
                              </td>
                              <td className="h-px w-px whitespace-nowrap">
                                <div className="px-6 py-1.5">
                                  <button className="ml-3">
                                    <div className="py-3">
                                      <span className="text-sm text-gray-500">
                                        {data?.location_name}
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </td>
                              <td className="h-px w-px whitespace-nowrap">
                                <div className="px-6 py-1.5">
                                  <button
                                    className="ml-3"
                                    onClick={() =>
                                      onPreviewScore(data?.score_id)
                                    }
                                  >
                                    <div className="py-1 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white">
                                      <i className="bi bi-eye"></i>
                                      View
                                    </div>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* End Table */}
                      {/* Footer */}
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
                        <div>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            <span className="font-semibold text-gray-800 dark:text-gray-200">
                              {scoreList?.total_count}
                            </span>{" "}
                            results
                          </p>
                        </div>
                        <div>
                          <div className="inline-flex gap-x-2">
                            <button
                              type="button"
                              className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                            >
                              <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                              </svg>
                              Prev
                            </button>
                            <button
                              type="button"
                              className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                            >
                              Next
                              <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* End Footer */}

                      <div
                        id="hs-bg-gray-on-hover-cards"
                        className={`hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto ${
                          open ? "open" : "hidden"
                        }`}
                      >
                        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-4xl lg:w-full m-3 lg:mx-auto">
                          <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700">
                            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                              <h3 className="font-bold text-gray-800 dark:text-gray-200">
                                Detailed Risk Report Analysis
                              </h3>
                              <div>
                                {/* Col */}
                                <div className="inline-flex gap-x-2 mr-5">
                                  <button
                                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                    onClick={handleDownloadPDF}
                                  >
                                    <i className="bi bi-download"></i>
                                    PDF
                                  </button>
                                  <button
                                    className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                                    onClick={handlePrint}
                                  >
                                    <i className="bi bi-printer-fill"></i>
                                    Print
                                  </button>
                                </div>
                                {/* Col */}
                                <button
                                  type="button"
                                  className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                                  onClick={toggleModal}
                                >
                                  <span className="sr-only">Close</span>
                                  <svg
                                    className="w-3.5 h-3.5"
                                    width={8}
                                    height={8}
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <PdfPreviewComponent
                                toggleModal={toggleModal}
                                myRiskdata={riskData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
