//@ts-nocheck

import { useEffect, useState } from "react";
import NewsTab from "./NewsTab";
import axios from "axios";

export default function NewsFeed() {
  const [news, setNews] = useState<any>();
  const newsdata = [
    {
      img: "/news1.png",
      title: "Reduce early due diligence costs",
      text: "It is a long established fact that a reader will be distracted by the readable ",
    },
    {
      img: "/smallplant.png",
      title: "Reduce losses by enhancing your...",
      text: "It is a long established fact that a reader will be distracted by the readable ",
    },
    {
      img: "/twosheeps.png",
      title: "Increase your agricultural lending...",
      text: "It is a long established fact that a reader will be distracted by the readable ",
    },
    {
      img: "/windmill.png",
      title: "Reduce early due diligence costs",
      text: "It is a long established fact that a reader will be distracted by the readable ",
    },
  ];

  const fetchNews = async () => {
    try {
      const response = await axios.get(
        "https://rss.app/feeds/v1.1/_Q8UuKarjC5hmwzTp.json"
      );
      console.log(response.data.items);
      setNews(response.data.items.slice(0, 5));
      const feedData = JSON.stringify(response.data.items.toSpliced(0, 5));

      localStorage.setItem("feed", feedData);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };
  useEffect(() => {
    // const fetchNews = async () => {
    //   try {
    //     const response = await axios.get(
    //       "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Frss.app%2Ffeeds%2F_Q8UuKarjC5hmwzTp.xml&api_key=urdevkavbzb9bliwal7qfvbckbmurfethtlkvrm4"
    //     );

    //     if (response.data) {
    //       setNews(response.data);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching news:", error);
    //   }
    // };

    // const listStockPricesAsync = async () => {
    //   const stockPrices = await listStockPrices(["AAPL", "GOOGL", "MSFT"]);

    //   setStockPrices(stockPrices);
    // };
    fetchNews();
    const interval = setInterval(() => {
      if (localStorage.getItem("feed").length < 5) {
        console.log("local feed no data");
        clearInterval(interval);
        return;
      }
      const localFeed = JSON.parse(localStorage.getItem("feed"));
      setNews(localFeed.slice(0, 5));
      const newFeed = JSON.stringify(localFeed.toSpliced(0, 5));
      localStorage.setItem("feed", newFeed);
    }, 60000);
    return () => clearInterval(interval);
    // listStockPricesAsync();
  }, []);

  return (
    <div>
      <div className="pt-[25px] py-[76px]">
        <div className="container">
          <div className="flex justify-between items-center">
            <div className="md:w-[60%]">
              <h2 className="text-[40px]  font-bold text-center md:text-left">
                Agriculture & Climate News
              </h2>
              {/* <p className="text-sm">
                At ADAPTA, we are committed to providing a secure and safe
                platform for our users. We understand the importance of security
                and have implemented several measures to ensure that your data
                is protected.
              </p> */}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5   gap-[20px] mt-[40px]">
            {news?.map(({ image, title, text }) => (
              <NewsTab title={title} image={image} description={text} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
