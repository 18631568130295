type NewsTabProps = {
  title: string;
  image: string;
  description: string;
};

export default function NewsTab({ title, image, description }: NewsTabProps) {
  return (
    <div>
      <div className="lg:max-w-[341px] md:h-[200px] rounded-[15px] object-cover overflow-hidden">
        <img className="md:min-h-[200px] object-cover " src={image} />
      </div>
      <div>
        <h4 className="text-sm pt-3">{title}</h4>
        <p className="mt-[10px] text-[#6B6B6B]">{description}</p>
        <a href="" className="text-sm font-semibold text-[#338FD9] mt-[10px]">
          Read More..
        </a>
      </div>
    </div>
  );
}
