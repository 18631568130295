export const requestData = [
  {
    id: 1,
    name: "Jane Cooper",
    account: "4400100235",
    location: "Nanyuki",
    address: "0.01667 37.07283",
    status: "Accepted",
    score: 70,
    created: "10 Sept 2023",
  },
  {
    id: 2,
    name: "Diana Mason",
    account: "5200200235",
    location: "Nakuru",
    address: "0.02447 37.06483",
    status: "Declined",
    score: 20,
    created: "14 Sept 2023",
  },
  {
    id: 3,
    name: "Kamau Kwema",
    account: "4200200665",
    location: "Mombasa",
    status: "Pending",
    address: "0.03547 37.02283",
    score: 50,
    created: "13 Sept 2023",
  },
  {
    id: 4,
    name: "John James",
    account: "1100208765",
    location: "New York",
    address: "0.03547 23.02283",
    status: "Pending",
    score: 40,
    created: "13 Sept 2023",
  },
  {
    id: 5,
    name: "Mercy Kartton",
    account: "2200200665",
    location: "Londan",
    address: "0.03547 19.22234",
    status: "Accepted",
    score: 60,
    created: "10 Sept 2023",
  },
  {
    id: 6,
    name: "David Harrison",
    account: "77003470115",
    location: "Mombasa",
    address: "0.03547 37.02283",
    status: "Declined",
    score: 20,
    created: "13 Sept 2023",
  },
];

export const requestTeamData = [
  {
    id: 1,
    name: "Jane Cooper",
    email: "jane@gmail.com",
    role: "Manager",
    type: "Employee",
    department: "No Department",
    status: "Active",
    created: "10 Sept 2023",
  },
];

export const requestRolesData = [
  {
    id: 1,
    role: "Manager",
    description: "Employee",
    permissions: [
      "Create member",
      "Delete member",
      "Suspend member",
      "Read Report",
      "Create Request",
      "Edit Request",
    ],
    created: "10 Sept 2023",
  },
  {
    id: 2,
    role: "Accountant",
    description: "Employee",
    permissions: [
      "Create member",
      "Read Report",
      "Create Request",
      "Edit Request",
    ],
    created: "8 Sept 2023",
  },
  {
    id: 3,
    role: "Customer Care",
    description: "Employee",
    permissions: ["Read Report", "Create Report", "Create Request"],
    created: "2 Sept 2023",
  },
  {
    id: 4,
    role: "Teller",
    description: "Employee",
    permissions: ["Read Report", "Create Report"],
    created: "13 Sept 2023",
  },
  {
    id: 5,
    role: "Technician",
    description: "Employee",
    permissions: [
      "Create member",
      "Delete member",
      "Suspend member",
      "Read Report",
      "Create Request",
      "Edit Request",
    ],
    created: "2 Aug 2023",
  },
  {
    id: 6,
    role: "Admin",
    description: "Employee",
    permissions: [
      "Create member",
      "Delete member",
      "Suspend member",
      "Read Report",
      "Create Request",
      "Edit Request",
      "Create Company",
      "Delete Company",
    ],
    created: "13 Sept 2023",
  },
];

export const requestCompaniesData = [
  {
    id: 1,
    name: "ADAPTA",
    country: "Kenya",
    countryCode: "KE",
    status: "Registred",
    created: "10 Sept 2023",
  },
];

export const requestActivityData = [
  {
    id: 1,
    company: "NCBA BANk",
    name: "James Mwangi",
    country: "Kenya",
    countryCode: "KE",
    status: "Registred",
    created: "10 Sept 2023",
  },
  {
    id: 2,
    name: "Hosea Andrews",
    company: "CITIBANK",
    country: "United States Of America",
    countryCode: "US",
    status: "Suspended",
    created: "14 Sept 2023",
  },
  {
    id: 3,
    name: "Brian Oreal",
    company: "Bank Of Londan",
    country: "United Kingdom",
    countryCode: "GB",
    status: "Pending",
    created: "13 Sept 2023",
  },
  {
    id: 4,
    name: "Husein Ali",
    company: "Abudabi Bank",
    countryCode: "AE",
    country: "United Arabs Emirates",
    status: "Registred",
    created: "13 Sept 2023",
  },
  {
    id: 5,
    name: "Jane Kiambo",
    company: "KCB BANK",
    countryCode: "KE",
    country: "Kenya",
    status: "Registred",
    created: "10 Sept 2023",
  },
  {
    id: 6,
    name: "Time Jones",
    company: "Wells Fargo",
    country: "United States Of Ameriaca",
    countryCode: "US",
    status: "Pending",
    created: "13 Sept 2023",
  },
];

export const resourcesData = [
  {
    id: 1,
    name: "Almond",
    img: "/pdf/img/almond.jpg",
    pdf: "/pdf/almond.pdf",
  },
  {
    id: 2,
    name: "Apple",
    img: "/pdf/img/apple.jpg",
    pdf: "/pdf/apple.pdf",
  },
  {
    id: 3,
    name: "Avocado",
    img: "/pdf/img/avocado.jpg",
    pdf: "/pdf/avocado.pdf",
  },
  {
    id: 4,
    name: "Banana",
    img: "/pdf/img/banana.jpg",
    pdf: "/pdf/banana.pdf",
  },
  {
    id: 5,
    name: "Cabbage",
    img: "/pdf/img/cabbage.jpg",
    pdf: "/pdf/cabbage.pdf",
  },
  {
    id: 6,
    name: "Cocoa",
    img: "/pdf/img/cocoa.jpg",
    pdf: "/pdf/cocoa.pdf",
  },
  {
    id: 7,
    name: "Common Beans",
    img: "/pdf/img/common-beans.jpg",
    pdf: "/pdf/common-beans.pdf",
  },
  {
    id: 8,
    name: "Cotton",
    img: "/pdf/img/cotton.jpg",
    pdf: "/pdf/cotton.pdf",
  },
  {
    id: 9,
    name: "Eggplant",
    img: "/pdf/img/eggplant.jpg",
    pdf: "/pdf/eggplant.pdf",
  },
  {
    id: 10,
    name: "Grape",
    img: "/pdf/img/grape.jpg",
    pdf: "/pdf/grape.pdf",
  },
  {
    id: 11,
    name: "Coffee",
    img: "/pdf/img/coffee.jpg",
    pdf: "/pdf/coffee.pdf",
  },
  {
    id: 12,
    name: "Cowpeas",
    img: "/pdf/img/cowpeas.jpg",
    pdf: "/pdf/cowpeas.pdf",
  },
  {
    id: 13,
    name: "Lettuce",
    img: "/pdf/img/lettuce.jpg",
    pdf: "/pdf/lettuce.pdf",
  },
  {
    id: 14,
    name: "Mango",
    img: "/pdf/img/mango.jpg",
    pdf: "/pdf/mango.pdf",
  },
  {
    id: 15,
    name: "Orange",
    img: "/pdf/img/orange.jpg",
    pdf: "/pdf/oranges.pdf",
  },
  {
    id: 16,
    name: "Onions",
    img: "/pdf/img/onions.jpg",
    pdf: "/pdf/onions.pdf",
  },
  {
    id: 17,
    name: "Palm",
    img: "/pdf/img/palm.jpg",
    pdf: "/pdf/palm.pdf",
  },
  {
    id: 18,
    name: "Papaya",
    img: "/pdf/img/papaya.jpg",
    pdf: "/pdf/papaya.pdf",
  },
  {
    id: 19,
    name: "Potato",
    img: "/pdf/img/potato.jpg",
    pdf: "/pdf/potato.pdf",
  },
  {
    id: 20,
    name: "Sweet Potato",
    img: "/pdf/img/potato.jpg",
    pdf: "/pdf/sweet-potato.pdf",
  },
  {
    id: 21,
    name: "Spinach",
    img: "/pdf/img/spinanch.png",
    pdf: "/pdf/spinach.pdf",
  },
  {
    id: 22,
    name: "Soybean",
    img: "/pdf/img/soy-beans.jpg",
    pdf: "/pdf/soybean.pdf",
  },
  {
    id: 23,
    name: "Sesame",
    img: "/pdf/img/sesame.jpg",
    pdf: "/pdf/sesame.pdf",
  },
  {
    id: 24,
    name: "Sunflower",
    img: "/pdf/img/sunflower.jpg",
    pdf: "/pdf/sunflower.pdf",
  },
  {
    id: 25,
    name: "Tea",
    img: "/pdf/img/tea.jpg",
    pdf: "/pdf/tea.pdf",
  },
  {
    id: 26,
    name: "Tangerine",
    img: "/pdf/img/tangerine.jpg",
    pdf: "/pdf/tangerine.pdf",
  },
  {
    id: 27,
    name: "Tomato",
    img: "/pdf/img/tomato.jpg",
    pdf: "/pdf/tomato.pdf",
  },
  {
    id: 28,
    name: "Green gram",
    img: "/pdf/img/green-gram.jpg",
    pdf: "/pdf/green-gram.pdf",
  },
  {
    id: 29,
    name: "Wheat",
    img: "/pdf/img/wheat.jpg",
    pdf: "/pdf/wheat.pdf",
  },
  {
    id: 30,
    name: "Macadamia",
    img: "/pdf/img/macadamia.jpg",
    pdf: "/pdf/macadamia.pdf",
  },
  {
    id: 31,
    name: "Maize",
    img: "/pdf/img/maize.jpg",
    pdf: "/pdf/maize.pdf",
  },
];
export const resourcesDataLivestock = [
  {
    id: 1,
    name: "Dairy Cattle",
    img: "/img/DairyCattle.png",
    pdf: "/pdf/Dairycattle.pdf",
  },
  {
    id: 2,
    name: "Pigs/Porks - Piglets Production/Full Cycle",
    img: "/img/Pig.png",
    pdf: "/pdf/Pig.pdf",
  },
  {
    id: 3,
    name: "Beef Cattle Feedlot",
    img: "/img/Beef-fidlot.png",
    pdf: "/pdf/Beeffeedlot.pdf",
  },
  {
    id: 4,
    name: "Poultry Hens/Layers (Egg)",
    img: "/img/Poultry-egg.png",
    pdf: "/pdf/Poultrylayers.pdf",
  },
  {
    id: 5,
    name: "Beef Cattle Breeding/Growing",
    img: "/img/BeefGrowing.png",
    pdf: "/pdf/Beefcattlebreedinggrowing.pdf",
  },
  {
    id: 6,
    name: "Poultry Broilers (Meat)",
    img: "/img/Chicken.png",
    pdf: "/pdf/Poultrybroilers.pdf",
  },
];

export const requestUsersData = [
  {
    id: 1,
    name: "Jane Cooper",
    email: "jane@gmail.com",
    role: "Manager",
    type: "Admin",
    department: "No Department",
    status: "Active",
    created: "10 Sept 2023",
    login: "10 Sept 2023, 11:24 PM",
  },
  {
    id: 2,
    name: "Peter Goldberg",
    email: "pertergolberg@gmail.com",
    role: "Accountant",
    type: "Client",
    department: "Accounts",
    status: "Suspended",
    created: "8 Sept 2023",
    login: "10 Sept 2023, 11:24 PM",
  },
  {
    id: 3,
    name: "Kamau Kwema",
    email: "kwame@yahoo.com",
    role: "Manager",
    type: "Client",
    department: "No Department",
    status: "Invited",
    created: "2 Sept 2023",
    login: "10 Sept 2023, 11:24 PM",
  },
  {
    id: 4,
    name: "John James",
    email: "johnjoe@yahoo.com",
    role: "Teller",
    type: "Contractor",
    department: "Loans",
    status: "Invited",
    created: "13 Sept 2023",
    login: "10 Sept 2023, 11:24 PM",
  },
  {
    id: 5,
    name: "Mercy Kartton",
    email: "mercyk@mailbox.com",
    role: "Teller",
    type: "Employee",
    department: "Manager",
    status: "Active",
    created: "2 Aug 2023",
    login: "10 Sept 2023, 11:24 PM",
  },
  {
    id: 6,
    name: "David Harrison",
    email: "davidharison@yahoo.com",
    role: "Admin",
    type: "Admin",
    department: "Loans",
    status: "Active",
    created: "13 Sept 2023",
    login: "10 Sept 2023, 11:24 PM",
  },
];

export const scoreTooltipData = [
  {
    tip: "It is a prolonged and recurring period of abnormally low precipitation (rainfall), which leads to water shortages in a specific region or area. It is a natural climatic phenomenon that occurs when there is insufficient rainfall or snowfall to meet the water needs of people, agriculture, and ecosystems.",
  },
  {
    tip: "Rainfall is critically important for crop production, and it plays a central role in the growth and development of plants. It replenishes soil moisture and provides the necessary hydration for plant growth. Without adequate rainfall, crops can suffer from drought stress and water deficiency, which can lead to reduced yields or crop failure.",
  },
  {
    tip: "Soil Organic Carbon (SOC) is one of the measures of Topsoil fertility. It is the amount of carbon stored in the soil in the form of organic matter. It is primarily derived from the decomposition of plants, animals, microbes, leaves, and wood on the ground. In a garden, when leaves fall off the trees and plants die, they fall onto the ground. With time, tiny creatures, often fungi, break down these bits and mix them into the soil. This mixture of broken-down plants, animals, and any other material is organic matter.",
  },
];
