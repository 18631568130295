import { useState, useEffect } from "react";
import MiniChart from "./MiniChart";

interface Content {
  level: number;
}

function Gauge(props: Content) {
  console.log(props, "from gauge");
  const [, setCurrentValue] = useState(props.level);
  useEffect(() => {
    setCurrentValue(props.level);
  }, [props]);

  return (
    <>
      <div className="w-[100%] md:w-auto">
        <div className="w-[100%] md:w-auto">
          <div className="group flex flex-col">
            <div className="mx-auto">
              <MiniChart
                idle={true}
                // @ts-ignore
                indexScore={props.level?.toFixed(0)}
                width={145}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gauge;
