import MainDashboard from "../../components/dashboards/main_dashboard";
import ClimateHelp from "../../components/help/Climate";
import SoilHelp from "../../components/help/Soil";
import WaterHelp from "../../components/help/Water";

export default function Scores() {
  return (
    <MainDashboard>
      <div>
        {/* FAQ */}
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 mx-auto">
          {/* Title */}
          <div className="mx-auto text-center mb-5 lg:mb-5">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
              Knowledge About the Scores
            </h2>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
              Answers to the most frequently asked questions.
            </p>
          </div>
          {/* End Title */}
          <div className="mx-auto">
            {/* Accordion */}
            <div className="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700 ">
              <div
                className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 dark:hs-accordion-active:bg-white/[.05] active"
                id="hs-basic-with-title-and-arrow-stretched-heading-one"
              >
                <button
                  className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-[#338FD9] transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                  aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one"
                >
                  Climate
                  <svg
                    className="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <svg
                    className="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div
                  id="hs-basic-with-title-and-arrow-stretched-collapse-one"
                  className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                  aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one"
                >
                  <ClimateHelp />
                </div>
              </div>
              <div
                className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 dark:hs-accordion-active:bg-white/[.05]"
                id="hs-basic-with-title-and-arrow-stretched-heading-two"
              >
                <button
                  className="hs-accordion-toggle text-[#338FD9] group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left  transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                  aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two"
                >
                  Water
                  <svg
                    className="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <svg
                    className="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div
                  id="hs-basic-with-title-and-arrow-stretched-collapse-two"
                  className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two"
                >
                  <WaterHelp />
                </div>
              </div>
              <div
                className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 dark:hs-accordion-active:bg-white/[.05]"
                id="hs-basic-with-title-and-arrow-stretched-heading-three"
              >
                <button
                  className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-left text-[#338FD9] transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400"
                  aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three"
                >
                  Soil
                  <svg
                    className="hs-accordion-active:hidden block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                  <svg
                    className="hs-accordion-active:block hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div
                  id="hs-basic-with-title-and-arrow-stretched-collapse-three"
                  className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three"
                >
                  <SoilHelp />
                </div>
              </div>
            </div>
            {/* End Accordion */}

            <div className="mx-auto text-center mb-5 lg:mb-5 mt-8">
              <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
                Breakdown Of Scores
              </h2>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                How will the system respond to scores.
              </p>
            </div>
            <div className="flex justify-center ">
              <table className="border w-100 md:min-w-[700px] table-auto	 ">
                <thead className="border">
                  <tr>
                    <th colSpan={12} className="p-3">
                      ADAPTA CS Thresholds
                    </th>
                  </tr>
                </thead>
                <tbody className="border p-2 ">
                  <tr>
                    <td>
                      <table>
                        <tr>
                          <td
                            colSpan={12}
                            className="bg-blue-300 p-2  font-bold"
                          >
                            Crops
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-2 w-[5%]">1</td>
                          <td className="border p-2 w-[35%]">
                            Composite Risk Score
                          </td>
                          <td className="border p-2 w-[10%]">{">"}= 75</td>
                          <td className="border p-2 w-[50%]">Do Not Proceed</td>
                        </tr>
                        <tr>
                          <td className="border p-2">2</td>
                          <td className="border p-2">Soil Risk</td>
                          <td className="border p-2">{">"}= 75</td>
                          <td className="border p-2">
                            Warning. Recommend Not Proceeding
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-2">3</td>
                          <td className="border p-2">Climate Score</td>
                          <td className="border p-2">{">"}= 75</td>
                          <td className="border p-2">
                            Warning. Recommend Not Proceeding
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-2">4</td>
                          <td className="border p-2">Climate and Water Risk</td>
                          <td className="border p-2">{">"}= 75</td>
                          <td className="border p-2">
                            Warning. Recommend Not Proceeding
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table>
                        <tr>
                          <td
                            colSpan={12}
                            className="bg-blue-300 p-2  font-semibold"
                          >
                            Livestock
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-2 w-[5%]">1</td>
                          <td className="border p-2 w-[35%]">
                            Composite Risk Score
                          </td>
                          <td className="border p-2 w-[10%]">{">"}= 75</td>
                          <td className="border p-2 w-[50%]">Do Not Proceed</td>
                        </tr>
                        <tr>
                          <td className="border p-2">2</td>
                          <td className="border p-2">Profitability</td>
                          <td className="border p-2">{">"}= 75</td>
                          <td className="border p-2">Do Not Proceed</td>
                        </tr>
                        <tr>
                          <td className="border p-2">3</td>
                          <td className="border p-2">Climate Score</td>
                          <td className="border p-2">{">"}= 75</td>
                          <td className="border p-2">
                            Warning. Recommend Not Proceeding
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-2">4</td>
                          <td className="border p-2">Climate and Water Risk</td>
                          <td className="border p-2">{">"}= 75</td>
                          <td className="border p-2">
                            Warning. Recommend Not Proceeding
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* End FAQ */}
      </div>
    </MainDashboard>
  );
}
