import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { axiosPrivateOid } from "../api/axiosPrivate";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import Select from "react-select";
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  // role_type: yup.string().required("Role is requried"),
  // username: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  // reset_password_period: yup.string().required("Description is required"),
  // two_factor_auth: yup.string().required("Description is required"),
});

export default function EditPopupUser({
  editToggle,
  setEditToggle,
  loadMembers,

  editUserData,
}: any) {
  console.log(editUserData.employeeType, "doa");

  const [employeeType, setEmployeeType] = useState(editUserData.employeeType);
  const { handleSubmit, control, register, setValue } = useForm({
    defaultValues: {
      name: editUserData.displayName,
      // role_type: {
      //   value: editUserData.employeeType,
      //   label: editUserData.employeeType,
      // },
      email: editUserData.mail,
    },
    // Set default values from the user object
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setValue("name", editUserData.displayName || ""); // Set default value for 'name'
    // setValue(
    //   "role_type",
    //   {
    //     value: editUserData.employeeType,
    //     label: editUserData.employeeType,
    //   } || ""
    // );
    setValue("email", editUserData.mail || ""); // Set default value for 'email'
  }, [editUserData, setValue]);

  const URL_EDIT_MEMBER = `/user/update`;
  const options = [
    { value: "user", label: "User" },
    { value: "company_admin", label: "Company admin" },
  ];

  useEffect(() => {
    if (editUserData) {
      setEmployeeType(editUserData.employeeType);
    }
  }, [editUserData]);
  async function onSubmit(data: any) {
    console.log(data, "submit data");
    data.status = "ACTIVE";
    data.role_type = employeeType;
    data.user_id = editUserData.id;
    delete data.reset_password_period;
    delete data.username;
    Swal.fire({
      icon: "warning",
      title: "Wait",
      text: "Please wait we are editing the user",
    });
    axiosPrivateOid<any>({
      method: "PATCH",
      url: URL_EDIT_MEMBER,
      data: data,
    })
      .then(() => {
        Swal.close();
        toast.success("Edited Successfully ");
        loadMembers();
        // toggleModalSave();
        setEditToggle((s: any) => !s);
        for (let i = 0; i < 3; i++) {
          setTimeout(() => {
            loadMembers();
          }, 7000); // 10000 milliseconds = 10 seconds
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  }

  return (
    <>
      <div
        id="register-modal"
        className={`hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto ${
          editToggle ? "open" : "hidden"
        }`}
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                Edit Member
              </h3>
              <button
                onClick={() => {
                  setEditToggle((s) => !s);
                }}
                type="button"
                className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-3.5 h-3.5"
                  width={8}
                  height={8}
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <form>
              <div className="p-4 overflow-y-auto">
                <label
                  htmlFor="input-label"
                  className="block text-sm font-medium mb-2 dark:text-white"
                >
                  Name
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={() => (
                    <input
                      type="text"
                      {...register("name")}
                      id="input-label"
                      className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                      placeholder="Name"
                    />
                  )}
                />
              </div>
              <div className="p-4 overflow-y-auto">
                <label
                  htmlFor="input-label"
                  className="block text-sm font-medium mb-2 dark:text-white"
                >
                  Role
                </label>

                {/* <Controller
                  name="role_type"
                  control={control}
                  render={() => (
                    // <select
                    //   {...register("role_type")}
                    //   className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                    //   name="category"
                    // >
                    //   <option id="0">user</option>
                    //   <option id="1">company_admin</option>
                    // </select>
                    <Select options={options} />
                  )}
                /> */}

                <Select
                  onChange={(selectedOption) =>
                    //@ts-ignore

                    setEmployeeType(selectedOption.value)
                  }
                  //@ts-ignore
                  options={options}
                  value={{
                    label: employeeType,
                    value: employeeType,
                  }}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      color: "black",
                    }),
                  }}
                />

                {/* <select
                                className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                               name="category" 
                                {...registerForm("role_type")}
                              >
                                <option id="0">User</option>
                                <option id="1">Company admin</option>
                              </select> */}
              </div>

              {/* <div className="p-4 overflow-y-auto">
                <label
                  htmlFor="input-label"
                  className="block text-sm font-medium mb-2 dark:text-white"
                >
                  Password Reset (In months)
                </label>
                <input
                  type="text"
                  id="input-label"
                  className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                  placeholder="Password resetting"
                  {...registerForm("reset_password_period")}
                />
              </div> */}
              {/* <div className="p-4 overflow-y-auto">
                <label
                  htmlFor="input-label"
                  className="block text-sm font-medium mb-2 dark:text-white"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="input-label"
                  className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                  placeholder="Username"
                  {...registerForm("username")}
                />
              </div> */}
              <div className="p-4 overflow-y-auto">
                <label
                  htmlFor="input-label"
                  className="block text-sm font-medium mb-2 dark:text-white"
                >
                  Email
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={() => (
                    <input
                      type="email"
                      {...register("email")}
                      id="input-label"
                      className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                      placeholder="email@company.com"
                    />
                  )}
                />
              </div>
              <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                <button
                  onClick={() => setEditToggle((s) => !s)}
                  type="button"
                  className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
