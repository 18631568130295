//@ts-nocheck
import { useNavigate, useParams } from "react-router-dom";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import { useEffect, useState } from "react";
import { axiosPrivateOid, axiosProfitablity } from "../../api/axiosPrivate";
import Swal from "sweetalert2";
import ActivityUsertable from "../../components/tables/ActivityUsertable";
import { CreditCard, FileX, SaveAll, Search } from "lucide-react";
import CompanyReportView from "../../components/CompanyReportView";
import Select from "react-select";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import { Bar } from "react-chartjs-2";
import { pdfOptions, replaceUnderscores } from "../../utils";
import generatePDF from "react-to-pdf";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "User Activity",
    },
  },
};

ChartJS.register(ArcElement, Tooltip, Legend);
export default function CompanyActivity() {
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState<any>({});
  const [activityDataLivestock, setActivityDataLivestock] = useState<any>({});
  const [creditStatus, setCreditStatus] = useState("");
  const { companyId } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const ACTIVTY_URL = `/searches/company/${companyId}?time_filter=last_semester`;

  // const labels = ["Onion", "Potato", "Maize", "Avocado"];
  const labels = activityData?.top_crops_searched?.map((item) => item[0]);
  const labelsLivestock = activityDataLivestock?.top_livestocks_searched?.map(
    (item) => {
      console.log(item, "lable");
      return replaceUnderscores(item[0]);
    }
  );

  const dataSetData = activityData?.top_crops_searched?.map((item) => item[1]);
  const dataSetDataLivestock =
    activityDataLivestock?.top_livestocks_searched?.map((item) => item[1]);
  const data = {
    labels,
    datasets: [
      {
        label: "# of crops searched",
        data: dataSetData,
        backgroundColor: [
          "rgb(248 195 58)",
          "rgba(20, 108, 148, 1)",
          "rgba(167, 93, 93, 1)",
        ],
      },
    ],
  };
  const dataLivestock = {
    labels: labelsLivestock,
    datasets: [
      {
        label: "# of livestock searched",
        data: dataSetDataLivestock,
        backgroundColor: [
          "rgb(248 195 58)",
          "rgba(20, 108, 148, 1)",
          "rgba(167, 93, 93, 1)",
        ],
      },
    ],
  };

  const labelBar = activityData?.users_search_credits
    ?.slice(0, 4)
    ?.map((item) => item?.displayName);

  const searchesData = activityData?.users_search_credits
    ?.slice(0, 4)
    ?.map((item) => item?.used_search_credits);
  const bardata = {
    labels: labelBar,
    datasets: [
      {
        label: "No. of Searches",
        data: searchesData,
        backgroundColor: [
          "rgb(210 53 1)",
          "rgb(25 108 204)",
          "rgb(204 25 109)",
          "rgb(193 25 204)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  async function getActivity() {
    axiosPrivateOid<any>({ method: "GET", url: ACTIVTY_URL })
      .then((data) => {
        console.log(data, "dataas");
        setActivityData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error.",
          text: "Something went wrong",
        });
        console.error("API Error:", error);
      });
  }
  async function getActivityLivestock() {
    const LIVESTOCK_ACTIVITY = `/company-history/read/${companyId}`;
    axiosProfitablity<any>({ method: "GET", url: LIVESTOCK_ACTIVITY })
      .then((data) => {
        console.log(data, "dataas");
        setActivityDataLivestock(data?.data);
        setCreditStatus(data?.data.credit_status);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error.",
          text: "Something went wrong",
        });
        console.error("API Error:", error);
      });
  }

  const optionsSelect = activityData?.users_search_credits?.map((item) => {
    return { label: item.displayName, value: item.user_id };
  });

  const handlePrint = () => {
    const printContents = document.getElementById("printablediv")?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };
  const getTargetElement = () => document.getElementById("printablediv");
  const handleDownloadPDF = () => {
    // const modalContent = document.getElementById("printablediv");
    // console.log("generatePDF:modalContent: ", modalContent);
    // html2pdf()
    //   .set({
    //     pagebreak: { mode: "avoid-all" },
    //   })
    //   .from(modalContent)
    //   .save("modal_content.pdf");
    console.log("pdf run");
    generatePDF(getTargetElement, pdfOptions);
  };

  useEffect(() => {
    getActivity();
    getActivityLivestock();
  }, []);
  console.log(activityDataLivestock, "hhhhhhhh");
  return (
    <MainDashboard>
      {loading ? (
        <>Loading...</>
      ) : (
        <div>
          <div className="flex justify-between items-center">
            <BreadHeader
              title={activityData?.name}
              description="Company activity with its user's detail"
            />
            <div className=" flex gap-2">
              <button className="h-9 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border   font-semibold border-1 dark:text-white hover:bg-grey-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
                <div
                  className={`h-3 w-3  ${
                    creditStatus === "AVAILABLE" ? "bg-green-300" : "bg-red-300"
                  }  rounded-full`}
                ></div>
                Credit Status{" "}
              </button>
              <button
                onClick={toggleModal}
                className="h-9 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-grey-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
              >
                <svg
                  className="w-3 h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
                View activity
              </button>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4 mb-7">
            <div className="min-h-[110px] flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#305934] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#EAF6FF] ">
              <div className="icon">
                <CreditCard size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Total Search Credits
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.max_search_credits}
                </p>
              </div>
            </div>

            {/* <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#38362a] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fff8d4]">
              <div className="icon">
                <CreditCard size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Most searched crops
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.top_crops_searched[0]},{" "}
                  {activityData?.top_crops_searched[1]},{" "}
                  {activityData?.top_crops_searched[2]}
                  {""}
                </p>
              </div>
            </div> */}

            {/* <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#ff9595a1] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fff1f1]">
              <div className="icon">
                <CreditCard size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Most active users
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.most_active_users[0].displayName},{" "}
                  {activityData?.most_active_users[1].displayName},{" "}
                  {activityData?.most_active_users[2].displayName}
                </p>
              </div>
            </div> */}

            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#968ff8b5] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#EBFFEC] min-h-[110px]">
              <div className="icon">
                <Search size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Remaining Search Credits
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.max_search_credits -
                    activityData?.used_search_credits}
                </p>
              </div>
            </div>

            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#ff6f3e5e] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#FFF2D1] min-h-[110px]">
              <div className="icon">
                <SaveAll size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Saved Searches
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.saved_search_results}
                </p>
              </div>
            </div>
            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#78ceed78] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#FFE0E0] min-h-[110px]">
              <div className="icon">
                <FileX size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Unsaved Searches
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.unsaved_search_results}
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 mb-7">
            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#305934] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#DDF5DF] min-h-[110px]">
              <div className="icon">
                <CreditCard size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Most Searched Crops
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.top_crops_searched?.[0]?.[0]}{" "}
                  {activityData?.top_crops_searched?.[1]?.[0]}{" "}
                  {activityData?.top_crops_searched?.[2]?.[0]}
                </p>
              </div>
            </div>

            {/* <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#38362a] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fff8d4]">
              <div className="icon">
                <CreditCard size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Most searched crops
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.top_crops_searched[0]},{" "}
                  {activityData?.top_crops_searched[1]},{" "}
                  {activityData?.top_crops_searched[2]}
                  {""}
                </p>
              </div>
            </div> */}

            {/* <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#ff9595a1] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fff1f1]">
              <div className="icon">
                <CreditCard size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Most active users
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.most_active_users[0].displayName},{" "}
                  {activityData?.most_active_users[1].displayName},{" "}
                  {activityData?.most_active_users[2].displayName}
                </p>
              </div>
            </div> */}

            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#968ff8b5] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#7e74fb26] min-h-[110px]">
              <div className="icon">
                <Search size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Most Searched Livestocks
                </h3>
                <p>
                  {replaceUnderscores(
                    activityDataLivestock?.top_livestocks_searched?.[0]?.[0]
                  )}{" "}
                  ,
                  {replaceUnderscores(
                    activityDataLivestock?.top_livestocks_searched?.[1]?.[0]
                  )}{" "}
                  ,
                  {replaceUnderscores(
                    activityDataLivestock?.top_livestocks_searched?.[2]?.[0]
                  )}
                </p>
              </div>
            </div>

            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#ff6f3e5e] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fe541936] min-h-[110px]">
              <div className="icon">
                <SaveAll size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Overall risk score
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.saved_search_results}
                </p>
              </div>
            </div>
            <div className="flex items-center p-3 md:p-3 gap-5 border shadow-sm rounded-xl dark:bg-[#78ceed78] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#5bc8ef29] min-h-[110px]">
              <div className="icon">
                <FileX size={35} />
              </div>
              <div>
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  Top searching users
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {activityData?.most_active_users?.[0]?.displayName},{" "}
                  {activityData?.most_active_users?.[1]?.displayName},{" "}
                  {activityData?.most_active_users?.[2]?.displayName}
                </p>
              </div>
            </div>
          </div>

          <div className="grid  grid-cols-[50%_25%_25%] gap-4 mb-7">
            <div className="shadow p-4 rounded dark:shadow-[#ffffff42]">
              <h4>User Searches</h4>
              <Bar options={options} data={bardata} />
            </div>
            <div className="shadow p-4 rounded dark:shadow-[#ffffff42]">
              <h4>Top Crops</h4>
              <Doughnut data={data} />
            </div>
            <div className="shadow p-4 rounded dark:shadow-[#ffffff42]">
              <h4>Top Livestock</h4>
              <Doughnut data={dataLivestock} />
            </div>
          </div>

          <div className="flex justify-between mb-4">
            <h1 className="block text-2xl font-bold mb-3 text-gray-800 sm:text-2xl dark:text-white">
              User activity table
            </h1>
            <Select
              onChange={(data) =>
                //@ts-ignore
                navigate(`/teams/activity/searches/${data?.value}`)
              }
              placeholder="Search..."
              className="dark:text-black w-32 font-bold border-red"
              options={optionsSelect}
              // styles={customStyles}
            />
          </div>

          <ActivityUsertable data={activityData?.users_search_credits} />

          <CompanyReportView
            handleDownloadPDF={handleDownloadPDF}
            activityData={activityData}
            toggleModal={toggleModal}
            isModalOpen={isModalOpen}
            handlePrint={handlePrint}
          />
        </div>
      )}
    </MainDashboard>
  );
}
