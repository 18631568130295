import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import LivestockList from "../../components/list/livestockList";

export default function LivestockResources() {
  return (
    <MainDashboard>
      <div>
        <BreadHeader
          title="Resources"
          description="List of Documents that provides more details about the Livestocks."
        />
        <LivestockList />
      </div>
    </MainDashboard>
  );
}
