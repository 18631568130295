export default function ClimateHelp() {
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          Drought Score
        </h1>

        <p className="leading-relaxed text-[#525252] text-base pb-3">
          It is a prolonged and recurring period of abnormally low precipitation
          (rainfall), which leads to water shortages in a specific region or
          area. It is a natural climatic phenomenon that occurs when there is
          insufficient rainfall or snowfall to meet the water needs of people,
          agriculture, and ecosystems.
        </p>
        <p className="leading-relaxed text-base pb-3">
          Droughts can have significant adverse impacts on various aspects of
          society, including agriculture, water resources, ecosystems, and human
          well-being.
        </p>
        <p className="leading-relaxed text-base pb-1">
          Some of the effects of drought on crop production include.
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            Reduced soil moisture: Drought leads to a shortage of soil moisture,
            making it difficult for seeds to germinate and for plants to grow.
            This can result in poor crop establishment and stunted growth.
          </li>
          <li className="mb-2">
            Water stress: Drought causes water stress in plants, which can lead
            to wilting, leaf curling, and reduced photosynthesis. These
            physiological changes can result in lower yields and poor crop
            quality.
          </li>
          <li className="mb-2">
            Decreased yields: Drought can directly reduce crop yields by
            limiting the amount of water available to crops, which affects their
            growth, development, and productivity. In severe cases, entire crops
            may fail.
          </li>
          <li className="mb-2">
            Altered crop development: Drought can disrupt the normal growth and
            development of crops. For instance, it can lead to early flowering
            and premature senescence, reducing the time available for fruit and
            seed formation
          </li>
          <li className="mb-2">
            Altered crop development: Drought can disrupt the normal growth and
            development of crops. For instance, it can lead to early flowering
            and premature senescence, reducing the time available for fruit and
            seed formation
          </li>
          <li className="mb-2">
            Increased susceptibility to pests and diseases: Stressed crops are
            more susceptible to pests and diseases, which can further reduce
            yields and crop quality.
          </li>
          <li className="mb-2">
            Crop loss and food insecurity: Severe drought can lead to the loss
            of entire crops, resulting in food shortages and increased food
            prices. This can lead to food insecurity for both local communities
            and regions that rely on affected areas for their food supply.
          </li>
          <li className="mb-2">
            Economic impact: Drought-induced crop failures can have significant
            economic consequences for farmers, agricultural communities, and the
            broader economy. Farmers may face financial losses, reduced income,
            and increased debt.
          </li>
          <li className="mb-2">
            Impact on livestock: Drought not only affects crop production but
            also reduces the availability of forage and water for livestock,
            leading to livestock losses, reduced meat and milk production, and
            increased costs for livestock management.
          </li>
          <li className="mb-2">
            Long-term soil degradation: Repeated or prolonged drought can lead
            to soil degradation, reduced soil fertility, and erosion, making it
            even more challenging to cultivate crops in the future.
          </li>
        </ul>
        <p className="leading-relaxed text-base pb-3">
          To mitigate the effects of drought on crop production, farmers and
          agricultural communities often employ various strategies, such as
          implementing drought-resistant crop varieties, improving irrigation
          practices, adopting water-saving techniques, and using soil
          conservation measures. Additionally, government policies, drought
          insurance, and emergency relief programs can help address the economic
          and social impacts of drought on agriculture.
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          Precipitation Score
        </h1>
        <p className="leading-relaxed text-base pb-3">
          Precipitation refers to any form of water, in liquid or solid state,
          that falls from the Earth's atmosphere to the surface. Precipitation
          can occur in various forms, including rain, snow, hail, sleet, and
          freezing rain.
        </p>
        <p className="leading-relaxed text-base pb-2">
          It is critically important for crop production, and it plays a central
          role in the growth and development of plants. It replenishes soil
          moisture and provides the necessary hydration for plant growth.
          Without adequate precipitation, crops can suffer from drought stress
          and water deficiency, which can lead to reduced yields or crop
          failure.
        </p>
        <p className="leading-relaxed text-base pb-2">
          Here are the key reasons why precipitation is vital for crop
          production.
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            Water Absorption: Plants absorb water through their roots from the
            soil. Water is a vital component of plant cells, making up a large
            percentage of their overall weight. It is essential for maintaining
            turgidity, which is the rigidity or firmness of plant cells, and for
            facilitating the movement of nutrients and minerals within the
            plant.
          </li>

          <li className="mb-2">
            Photosynthesis: Precipitation supplies the water needed for
            photosynthesis, the process by which plants convert light energy
            into chemical energy (sugars) using carbon dioxide and water. Water
            is one of the reactants in the photosynthesis equation, and without
            it, plants cannot produce glucose, which is the primary source of
            energy for growth and metabolism.
          </li>

          <li className="mb-2">
            Nutrient Uptake: Precipitation helps dissolve and transport
            essential nutrients and minerals from the soil into the plant roots.
            These nutrients, such as nitrogen, phosphorus, potassium, and
            micronutrients, are essential for various biochemical processes
            within plants, including protein synthesis, enzyme activation, and
            cell growth.
          </li>

          <li className="mb-2">
            Temperature Regulation: Precipitation can help moderate temperature
            extremes by cooling the surrounding environment. This moderation in
            temperature helps prevent heat stress in plants, especially during
            hot and dry periods, which can otherwise inhibit photosynthesis and
            lead to wilting and damage.
          </li>

          <li className="mb-2">
            Seed Germination and Establishment: Adequate moisture from
            precipitation is critical for seed germination and seedling
            establishment. Seeds require water to initiate metabolic processes
            that lead to germination, and young seedlings need continuous
            moisture for root and shoot development until they can establish
            themselves in the soil.
          </li>

          <li className="mb-2">
            Drought Resistance: While excessive precipitation can lead to
            waterlogging and other problems for plants, regular but moderate
            precipitation helps build resilience against drought conditions.
            Plants that receive sufficient moisture during periods of
            precipitation can store water in their tissues, develop deeper root
            systems, and withstand dry spells more effectively.
          </li>
        </ul>
        <p className="leading-relaxed text-base pb-3">
          In summary, precipitation is essential for plant growth and health as
          it provides the water necessary for photosynthesis, nutrient uptake,
          cell expansion, temperature regulation, seed germination, and drought
          resistance. Understanding the relationship between precipitation and
          plant growth is crucial for sustainable agriculture, ecosystem
          management, and maintaining biodiversity in terrestrial ecosystems
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          Temperature Score
        </h1>

        <p className="leading-relaxed text-base pb-3">
          Temperature is both a driver and a consequence of climate change
        </p>
        <p className="leading-relaxed text-base pb-3">
          Here's how temperature influences climate change:
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            Greenhouse Effect: Temperature plays a crucial role in the
            greenhouse effect, which is the process by which certain gases in
            the Earth's atmosphere trap heat from the sun. Increased
            concentrations of greenhouse gases, such as carbon dioxide (CO2),
            methane (CH4), and nitrous oxide (N2O), enhance the greenhouse
            effect, leading to a rise in global temperatures. This phenomenon is
            the primary driver of anthropogenic (human-caused) climate change.
          </li>

          <li className="mb-2">
            Changes in Weather Patterns: Temperature influences weather patterns
            and climate variability. Warmer temperatures can lead to changes in
            precipitation patterns, including increased intensity of rainfall in
            some regions and prolonged droughts in others. Temperature changes
            can also influence the frequency and intensity of extreme weather
            events, such as heat waves, hurricanes, and storms.
          </li>

          <li className="mb-2">
            Ecological Impacts: Temperature influences the distribution,
            behavior, and survival of plant and animal species. Temperature
            changes can alter ecosystems, including shifts in habitat ranges,
            changes in species composition, and disruptions to ecological
            interactions such as pollination and predatorprey relationships.
          </li>

          <li className="mb-2">
            Human Health and Agriculture: Temperature directly affects human
            health and agriculture. Rising temperatures can increase the
            frequency and intensity of heatwaves, leading to heat-related
            illnesses and mortality. Temperature changes can also impact
            agricultural productivity, water availability, and the spread of
            vector-borne diseases, affecting food security and public health.
          </li>

          <li className="mb-2">
            Changes in Weather Patterns: Temperature influences weather patterns
            and climate variability. Warmer temperatures can lead to changes in
            precipitation patterns, including increased intensity of rainfall in
            some regions and prolonged droughts in others. Temperature changes
            can also influence the frequency and intensity of extreme weather
            events, such as heat waves, hurricanes, and storms.
          </li>

          <li className="mb-2">
            Ocean Circulation: Temperature plays a crucial role in driving ocean
            circulation patterns, which help distribute heat around the globe.
            Temperature changes can disrupt ocean currents, such as the Gulf
            Stream and the Atlantic Meridional Overturning Circulation (AMOC),
            which can have far-reaching impacts on regional climates, marine
            ecosystems, and weather patterns.
          </li>
        </ul>
      </div>
      {/* End Card */}
    </>
  );
}
